import React, { useState, useEffect } from 'react';
import { ChatBubbleLeftRightIcon,EnvelopeIcon,EyeSlashIcon,EyeIcon,CheckCircleIcon,UserPlusIcon,UserMinusIcon } from '@heroicons/react/20/solid';
import { EnvelopeOpenIcon } from '@heroicons/react/24/outline';
import axios from '../api/axios';
import useAuth from '../hooks/useAuth';

const statuses = {
  new: 'text-indigo-800 bg-indigo-50 ring-indigo-600/20',
  expired: 'text-red-700 bg-red-50 ring-red-600/20',
  name: 'text-cyan-800 bg-cyan-50 ring-cyan-600/20',
  resolved: 'text-green-700 bg-green-50 ring-green-600/20',
}

const textColour = {
  new: 'text-indigo-700',
  expired: 'text-red-700',
  resolved: 'text-gray-500'
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const backendUrl = process.env.REACT_APP_BACKEND_URL;

async function updateConversation(object){
  axios.patch(`/conversation/${object._id}`, object)
      .then(res => console.log(res.data))
      .catch(error => {
        console.log(error);
  });
}

export default function Conversation({conversation}) {

  const {auth} = useAuth();

  const [conversationObject, setConversationObject] = useState({});
  const lastReceivedDate = new Date(conversation.lastreceived);
  const currentDate = new Date();
  const timeDifference = currentDate - lastReceivedDate;
  const secondsDifference = timeDifference / 1000;
  const minutesDifference = secondsDifference / 60;
  const hoursDifference = minutesDifference / 60;
  const daysDifference = hoursDifference / 24;

  const [resolved, setResolved] = useState(conversation.status === 'resolved');
  const [unread, setUnread] = useState(conversation.unread || false);
  const [status, setStatus] = useState(conversation.status || null);
  const [agents, setAgents] = useState(conversation.agent || []);

  const [expired, setExpired] = useState(false);

  const formatTime = (value, unit) => {
    if (value === 1) {
      return `${value} ${unit}`;
    } else {
      return `${value} ${unit}s`;
    }
  };

  const timeSinceLastReceived = (() => {
    if (daysDifference >= 1) {
      return formatTime(Math.floor(daysDifference), 'day');
    } else if (hoursDifference >= 1) {
      return formatTime(Math.floor(hoursDifference), 'hour');
    } else if (minutesDifference >= 1) {
      return formatTime(Math.floor(minutesDifference), 'minute');
    } else {
      return formatTime(Math.floor(secondsDifference), 'second');
    }
  })();

  useEffect(() => {
    setResolved(status === 'resolved');
  }, [status]);

  useEffect(() => {

    if(daysDifference >= 1 && conversation.status != 'expired' && conversation.status != 'resolved'){

      let tempConversation = conversation;
      tempConversation.session = false;
      tempConversation.status = 'expired';
      setStatus('expired');
      updateConversation(tempConversation);
    }

  }, [daysDifference])

  function markasunread(boolean) {
    setUnread(boolean);
    const updatedConversation = { ...conversation, unread: boolean };
    setConversationObject(updatedConversation);
    updateConversation(updatedConversation);
  }

  function followConversation(boolean) {
    let user = auth?.username;
    let tempAgents = agents;
    if(boolean){
      tempAgents.push(user);
    } else {
      tempAgents = tempAgents.filter(e => e !== user);
    }
    const updatedConversation = { ...conversation, agent: tempAgents };
    setConversationObject(updatedConversation);
    updateConversation(updatedConversation);
    setAgents(tempAgents);
  }

  function markasresolved(boolean) {
    const updatedConversation = { ...conversation };
    if (!boolean) {
      updatedConversation.status = 'resolved';
      updatedConversation.resolved = true;
    } else {
      updatedConversation.status = null;
      updatedConversation.resolved = false;
    }
    setResolved(boolean);
    setStatus(updatedConversation.status);
    setConversationObject(updatedConversation);
    updateConversation(updatedConversation);
  }

  return (
        <li key={conversation._id} className={

          "flex items-center justify-between gap-x-6 py-5"
        
        }>
          <div className="min-w-0">
            <div className="flex items-start gap-x-3">

                <a href={'conversation/'+conversation._id}>
                  
                  <p className={

                    unread ? (
                      `text-sm font-semibold leading-6 ${textColour[status]}`
                    ) : (
                      `text-sm font-normal leading-6 ${textColour[status]}`
                    )

                  }>

                  {
                   unread ?
                    
                    (<EnvelopeIcon className="h-4 w-4 inline-block relative"/>)

                    :
                    (<EnvelopeOpenIcon className="h-4 w-4 inline-block relative -top-0.5"/>)
                  }

                    {" "+conversation.name}

                  </p>

                </a>
                {

                status != null ? (

                <p
                    className={classNames(
                    statuses[status],
                    'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                    )}
                >
                    {conversation.status}
                </p>) : ''


                }

                {agents && agents.length > 0 ? (

                agents.map(agent => (

                      <p
                      className={classNames(
                      statuses['name'],
                      'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                      )}
                      >
                      {agent.split('@')[0]}
                      </p>

                      ))
                ) : (
                    <p>
                    </p>
                )}
            </div>
            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
              <p className="whitespace-nowrap">
                Last message recieved {timeSinceLastReceived} ago
              </p>
              <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                <circle cx={1} cy={1} r={1} />
              </svg>
              <p className="truncate">Campaign: {conversation.campaign != null ? conversation.campaign : "n/a"}</p>
            </div>
          </div>
          <div className="flex flex-none items-center gap-x-4">
            <a
              href={'conversation/'+conversation._id}
              title={"Chat with "+conversation.name} className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
            >
                <ChatBubbleLeftRightIcon className="h-5 w-5" aria-hidden="true" />
            </a>

            {
              unread ? 

              <button
              title={"Mark as read"} onClick={() => markasunread(false)} className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
              >
                <EyeIcon className="h-5 w-5" aria-hidden="true" />
            </button>

            :

            <button
              title={"Mark as unread"} onClick={() => markasunread(true)} className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
              >
                <EyeSlashIcon className="h-5 w-5" aria-hidden="true" />
            </button>

            }

            {
              agents.includes(auth?.username) ? 

              <button
              title={"Unfollow conversation"} onClick={() => followConversation(false)} className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
              >
                <UserMinusIcon className="h-5 w-5" aria-hidden="true" />
            </button>

            :

            <button
              title={"Follow conversation"} onClick={() => followConversation(true)} className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
              >
                <UserPlusIcon className="h-5 w-5" aria-hidden="true" />
            </button>

            }

            {
            resolved ? 

              <button
              title={"Mark as resolved"} onClick={() => markasresolved(true)} className="hidden rounded-md bg-green-200 px-2.5 py-1.5 text-sm font-semibold text-green-900 hover:text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
              >
                <CheckCircleIcon className="h-5 w-5" aria-hidden="true" />
              </button>

            :

              <button
                title={"Mark as unresolved"} onClick={() => markasresolved(false)} className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                >
                  <CheckCircleIcon className="h-5 w-5" aria-hidden="true" />
              </button>

            }
          </div>
        </li>
  )
}
